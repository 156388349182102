import PropTypes from 'prop-types';

export const ImagePropType = PropTypes.shape({
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
});

const MetaPropType = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  provider: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: ImagePropType,
});

export const FAQPropType = PropTypes.shape({
  faq: PropTypes.shape({
    title: PropTypes.string,
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
});

export const Block = PropTypes.shape({
  alt: PropTypes.string,
  ctaDisclaimer: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  customHtml: PropTypes.string,
  description: PropTypes.string,
  descriptionAlignment: PropTypes.string,
  descriptionSize: PropTypes.string,
  image: ImagePropType,
  title: PropTypes.string,
  titleAlignment: PropTypes.string,
  titleImage: ImagePropType,
  titleSize: PropTypes.string,
  responsiveImageSet: PropTypes.arrayOf(PropTypes.shape({
    desktopImage: ImagePropType,
    mobileImage: ImagePropType,
    tabletImage: ImagePropType,
  })),
  imageBesideCta: ImagePropType,
  offsetLeft: PropTypes.string,
  offsetRight: PropTypes.string,
});

export const DynamicSection = PropTypes.shape({
  backgroundColor: PropTypes.string,
  blocks: PropTypes.arrayOf(Block),
  ctaDisclaimer: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  displayName: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  titleImage: ImagePropType,
  offsetLeft: PropTypes.string,
  offsetRight: PropTypes.string,
  textBgColor: PropTypes.string,
});

export const LandingPagePropType = PropTypes.shape({
  actions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    trigger: PropTypes.string,
    name: PropTypes.string,
    eventAttributes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  canonicalUrl: PropTypes.string,
  ctaEventName: PropTypes.string,
  faqs: PropTypes.shape({
    questions: PropTypes.arrayOf(FAQPropType),
    title: PropTypes.string,
  }),
  imageSets: PropTypes.arrayOf(PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape({
      alt: PropTypes.string,
      image: ImagePropType,
    })),
    name: PropTypes.string,
  })),
  meta: PropTypes.arrayOf(MetaPropType),
  name: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    offers: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      price: PropTypes.shape({
        currency: PropTypes.string,
        amount: PropTypes.number,
      }),
    })),
    productImages: PropTypes.arrayOf(PropTypes.shape({
      image: ImagePropType,
    })),
  }),
  quotes: PropTypes.arrayOf(PropTypes.shape({
    quote: PropTypes.shape({
      quote: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      alt: PropTypes.string,
      image: ImagePropType,
    }),
  })),
  saveHeadline: PropTypes.string,
  sectionList: PropTypes.arrayOf(DynamicSection),
  sections: PropTypes.arrayOf(PropTypes.shape({
    ctaDisclaimer: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    description: PropTypes.string,
    footer: PropTypes.string,
    image: ImagePropType,
    imageAlt: PropTypes.string,
    mobileImage: ImagePropType,
    name: PropTypes.string,
    title: PropTypes.string,
  })),
  statsHeadline: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
});

export const AboutUsPagePropType = PropTypes.shape({
  member: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    sort: PropTypes.string,
    link: PropTypes.string,
    bioImage: ImagePropType,
    bio: PropTypes.string,
    memberExecutiveTeam: PropTypes.bool,
  })),
  meta: PropTypes.arrayOf(MetaPropType),
  pressCoverage: PropTypes.arrayOf(PropTypes.shape({
    image: ImagePropType,
    alt: PropTypes.string,
    content: PropTypes.string,
  })),
  sections: PropTypes.arrayOf(PropTypes.shape({
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    description: PropTypes.string,
    image: ImagePropType,
    imageAlt: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
  })),
  title: PropTypes.string,
  url: PropTypes.string,
});
